import React from 'react';

import { Box, Typography } from "@material-ui/core";
import StarIcon from '@material-ui/icons/Star';
import { AboutSectionContainer } from '@product-site-frontend/shared';
import { times } from 'lodash';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { reviews } from "./data/reviews";

export default function SectionSlider() {
  const SampleNextArrow = props => {
    // eslint-disable-next-line react/prop-types
    const { onClick } = props;
    return (
      <div
        onClick={onClick}
        style={{
          fontSize: 0,
          lineHeight: 0,
          cursor: "pointer",
          position: 'absolute',
          top: '50%',
          transform: 'translate(0, -50%)',
          right: '-25px',
          display: "block",
          backgroundImage: 'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAABUCAYAAACY7ffvAAADWUlEQVRYhbXZbahURRzH8c%2BaiIpYoVIoEUYkSGWgFCS9iOwJIfJFCSFIBilGSWaW95pv9GppGRZGBRVRCBUoCFJQERQFRUFqGJYYEpU9iD2Yhtm9vZh7ZHZ2T967%2B%2B8HC%2Fv%2Fze58Z8458%2FCf09h%2F4CCMwxLciBswIEgj8TBWYMKgdyt2RAFG4LKscuhFIxLQh%2F7Mm4mbIwH7tF6S3kgArNN8Y6%2FGdZGAz7GrKAvpxYjs%2B7qi7FrMjgR8jHeK8tWRAFhbxDdhViTgfXxQeF31ogTQ2otbcHkk4G18ksUN9EQCaO3FbZgWCdiF3cXvOupFHWBA67i4AxdFAWA7vsziamoPA%2FRLM22uhbggCgCv4UAWj8LKSMApbCi8u3B%2BFABexaEsHoMHIgEnsbHwlmBiFABexA9ZPA73RwL%2BwqbCuwfnRAHgefycxWfj3kjAn9hceMukyxUCgGdwNIsnYGkk4Hc8VXjLpUc3BABb8EcWn4e7IwFHsbXwHpSmkRAAPInjWTwFiyIBP%2BG5wlspTekhAHhcGoCVpmJBJOB7vFR4q3BWFAAew99ZfAlujwQcwiuF1yNLYLoFwKPSwlTpUsyLBHyN1wvvdC8iAPxHGhYFqE3DogDUpGGRgHZpWE8koJ0akYAZmFt4fZGARzSfEHyEd6MA02WDa1B9xD1FPUVdn%2BHNKMDFmF946w0%2BshGAVZoXmi9kg65bwIVaF5nTrY8APKR5sf9KMfF1A5iMOwtvA%2F6JAqzA6Cz%2BRsolmtQpYBIWF95GzQtPV4DlGJvF30k5RIs6AZyrdcO7ScqEQgDLMD6Lf5Ryh7YaLmA87iu8zTgRBVgqXaJKR6ScoVbDAYyVbm6uLTgWBVgsPZ6VfsPTZ%2FrTUAGjpYGVayt%2BjQIskqaGSsekHOGMGgqg3QHIs%2FglCrBAmpYrncATQ6l8KICR0oKS6wUcjgLMl5bESu0ORjoGtDsIfBnfRgHmSduRSqekXGBYqgM0pI1Urm04GAWYK20FK%2FVLi%2FmwVQcoW%2F8G9kcBrseVWTygw9bXAcrW78SeKMA1g59c5RFzV4A1RfwWPo0CXIU5RXlXrS8B5auU9%2FBhFOAKbdKfbivPAau1SX%2BiALXpTxSgV036EwXYK%2B1vKvUJfCPe%2BL9fuf8LQtyhzUysgSEAAAAASUVORK5CYII%3D)',
          width: '12px',
          height: '42px',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
        }}
      />
    );
  }

  const SamplePrevArrow = props => {
    // eslint-disable-next-line react/prop-types
    const { onClick } = props;
    return (
      <div
        onClick={onClick}
        style={{
          fontSize: 0,
          lineHeight: 0,
          cursor: "pointer",
          position: 'absolute',
          top: '50%',
          transform: 'translate(0, -50%)',
          left: '-25px',
          display: "block",
          backgroundImage: 'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAABUCAYAAACY7ffvAAADc0lEQVRYhbXYa4hVVRjG8d8ZJsmyi3QvQoouJN2gKIiCKIQkCBVEAiMyumCU2L3RPs1oNysqlAxKJEEEoyAqJYSgKAoNqZAKMSIqooZKBpNpcPqwzpF11j7OnDn77YUN53k4e%2F33Wnuvtd53Nb7bu09gNLC9ea3HSCMYsABvNX8PY01fYOMNDGT6JFwcCZiLKzJ9CKsjASsK%2FTb2RAFuxDWZHscQRAFWFvo97I4CXIvrC2%2Bw9SMCUI79h%2FgiCnAlbiq8wVzUBZRj%2F3HzCgFcilsKb7D8Ux3AgDR7W%2FG5NP4hgAuxsPCGOv2xV8CK4t7d0rcfAjgXtxbekDR7QwCPoz%2FTe6R1p2NMFXA2bi%2B81dLKGQJ4FNMyvRdbJrphKoDTcWfhPYWxKMBDmJ7pH%2FHmZDd1CzgZ9xbeM%2Fg3CrAcMzL9CzZ0c2M3gBNxX%2BGtwcEowP04IdO%2FSzlPVzEZYAaWFd4LOBAFWCrlN634E%2Bu6bXwywHQ8WHgvYX8U4C6clun9eHkqjU8EmCYtC3msk4YoBLAEZ2X6gPRypxydAP2qT79e%2BjxDAItxTqYPShOrpygBfXii8DZIS0MIYBEuyPSotKj1HDmgofr0m6RlOQQwD5dkekzaUGpFC9BQTWK3SFtiCKBj%2BVO38RzQsfyJAtzgCOVPFOCI5U8U4H%2BNPqwqvJtxWSRgBz7NvIbqsNUCUO3FAlwUCfgAuwq%2F%2FHRrAcZVJ9YinBcFIE2urzPdr7r41QKMqy5uizErCkBa4L7PdKfNvxbgkGovluCMKABpk%2Fkh00fjkUjAGJ4tvLtxShQA3sDPmT5WNY2sBRjFc4W3FDOjAPAafsv08XggEvCParq4DMdFAUgJ73CmZ6qWU7UAI1JNkMdyHBMFgFfwd6ZPxT2RgL%2BwtvAeliZgCABelIarFWfijkjAH6ql62M4KgoAz0ufbitm4bZIwK94vfDKw6laANIiOJrp86WtNQzwEzYW3sBE7fSS2T2t%2FRBqNuZHAvZhc%2BGt1H5IWwtA9SDwcinlDAN8i62F1zHdrJNdr9J%2BGHs15kQCvsK7hfdkJIDqcf51zSsMsBPbCq%2BtFxEVTpn6z8FVkYBP8FHhHe5FVI1WVqWHy7AowA58lumGZi8iq8zyXczH7EjA%2B%2Fgy030YiASMa%2B%2FFML6ZcDfqId6R3sc2vIqR%2FwCONKT4Esge6QAAAABJRU5ErkJggg%3D%3D)',
          width: '12px',
          height: '42px',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
        }}
      />
    );
  }

  const settings = {
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          adaptiveHeight: true,
        }
      },
    ]
  };

  const styles = {
    titleH2: {
      fontSize: '36px',
      textAlign: 'center',
      fontWeight: 'bold',
      marginBottom: '30px',
    },
    slider: {
      maxWidth: { sm: '90%', md: '400px', lg:  '900px' },
      margin: '0 auto',
    },
    starts: {
      marginBottom: '30px',
      textAlign: 'center',
    },
    sliderText: {
      textAlign: 'justify',
      fontWeight: 'normal',
      marginLeft: '20px',
      marginRight: '20px',
      padding: '0 0 60px 0',
      borderBottom: '2px solid #d9dcde',
    },
    sliderName: {
      textAlign: 'center',
      fontWeight: 'bold',
      padding: '25px 20px 0',
      position: 'relative',
      '&:before': {
        content: '""',
        border: '13px solid transparent',
        borderTop: '13px solid #d9dcde',
        position: 'absolute',
        top: '0',
        left: 'calc(50% - 15px)',
      },
      '&:after': {
        content: '""',
        border: '13px solid transparent',
        borderTop: '13px solid #ffffff',
        position: 'absolute',
        top: '-3px',
        left: 'calc(50% - 15px)',
      },
    }
  }

  return (
    <AboutSectionContainer sxContainer={{ mb: { xs: 5, lg: 8 } }}>
      <Typography
        align={"center"}
        sx={{ ...styles.titleH2 }}
        variant="h2">
        Что говорят клиенты об Arenza
      </Typography>

      <Box sx={{ ...styles.slider }}>
        <Slider {...settings}>
          {reviews.map((elem, index) => (
            <Box key={index}>
              <Box sx={{ ...styles.starts }}>
                {times(elem.star, () => (<i><StarIcon sx={{color: '#f6863a'}} /></i>))}
              </Box>

              <Typography dangerouslySetInnerHTML={{__html: elem.text}}  sx={{ ...styles.sliderText }} />

              <Box sx={{ ...styles.sliderName }}>
                {elem.name}
                {elem.company.length > 0 && (
                  `, ${elem.company}`
                )}
                {elem.site.length > 0 && (
                  <>
                    {' '} (<a href={elem.site} rel="noreferrer" style={{color: '#2484c6'}} target="_blank">{elem.site}</a>)
                  </>
                )}
              </Box>
            </Box>
          ))}
        </Slider>
      </Box>
    </AboutSectionContainer>
  );
}