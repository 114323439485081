import React from 'react';

import { Box, Typography } from '@material-ui/core';
import { AboutSectionContainer } from '@product-site-frontend/shared';

export default function SectionWhyArenza() {
  const styles = {
    titleH2: {
      mb: { xs: 4, md: 5, lg: 6 },
      fontSize: '36px !important',
      textAlign: 'center',
      fontWeight: 'bold'
    },
    subtitle: {
      mb: { xs: 4, md: 5, lg: 6 },
      fontSize: '22px !important',
      textAlign: 'center',
    },
    blockWrapper: {
      display: { xs: 'block', lg: 'flex' },
      justifyContent: 'space-between',
      mb: 8,
    },
    block: {
      width: { xs: '100%', lg: 'calc(100% / 3)' },
      margin: { lg: "0 30px" },
      marginBottom: { xs: '40px', lg: '20px' },
      position: 'relative',

      '&:first-child': {
        marginLeft: '0'
      },
      '&:last-child': {
        marginRight: '0'
      },
      '&::after': {
        content: '" "',
        width: { xs: '50%', lg: '1px' },
        height: { xs: '1px', lg: '25%' },
        background: '#d9dcde',
        display: 'block',
        position: 'absolute',
        top: { xs: '120%', lg: '25%' },
        right: { xs: '25%', lg: '-10%' },
      },
      '&:last-child::after': {
        display: 'none',
      }
    },
    titleBlock: {
      marginBottom: '15px',
      fontSize: { xs: '26px', lg: '18px' },
      fontWeight: 500,
      textAlign: 'center',
    },
    descriptionBlock: {
      fontSize: { xs: '18px', lg: '16px' },
      textAlign: 'center',
      fontWeight: 'normal',
    }
  }

  return (
    <AboutSectionContainer sxContainer={{ mb: { xs: 5, lg: 8 } }}>
      <Typography sx={{ ...styles.titleH2 }} variant="h2">
        Почему Arenza?
      </Typography>
      <Typography sx={{ ...styles.subtitle }} variant="subtitle2">
        Подключив инструмент для финансирования Arenza, вы увеличите продажи и расширите клиентскую базу
      </Typography>

      <Box sx={{ ...styles.blockWrapper}}>
        <Box sx={{ ...styles.block}}>
          <Typography component="div" sx={{ ...styles.titleBlock }}>
            Двойная выгода
          </Typography>
          <Typography component="div" sx={{ ...styles.descriptionBlock }}>
            Мы берем клиентские затраты на себя, а также выплачиваем вам комиссионное вознаграждение
          </Typography>
        </Box>
        <Box sx={{ ...styles.block}}>
          <Typography component="div" sx={{ ...styles.titleBlock }}>
            Рост конверсии
          </Typography>
          <Typography component="div" sx={{ ...styles.descriptionBlock }}>
            Мы делаем покупки более доступными для ваших потенциальных клиентов, превращая их в реальных
          </Typography>
        </Box>
        <Box sx={{ ...styles.block}}>
          <Typography component="div" sx={{ ...styles.titleBlock }}>
            Увеличение среднего чека
          </Typography>
          <Typography component="div" sx={{ ...styles.descriptionBlock }}>
            Благодаря нам ваши клиенты имеют возможность совершать крупные покупки здесь и сейчас, а вы получаете больше прибыли
          </Typography>
        </Box>
      </Box>
    </AboutSectionContainer>
  );
}