import alfabank from "../images/partners/arenza_partner_alfabank-36e29c11c84b85a2b350b09eb2c950c143e9974a874372927386beccc5d51ff3.png"
import cassby from "../images/partners/arenza_partner_cassby-f309032af9f3a11068562c2abde2b4ebb285557d1b2d7dea5bf9cae2c33586fa.png"
import biztechnology from "../images/partners/arenza_partner_delovietech-f5be917e6bda3db7689ed4ac9a3a3882fcab512f8e4a57504784ff7ab383ddfb.png"
import garo from "../images/partners/arenza_partner_garo-e9b5f5c5025aed84d9cd690dd1d4894ce4d464081e6bda77f13e403a5077c847.png"
import iport from "../images/partners/arenza_partner_iport-b3952f6d69146c9cf55414b2e59cdf3d16d3acd8b6493c32598f72336d0cc029.png"
import kns from "../images/partners/arenza_partner_kns-5df01bb73187a33780cdceb0bd68897dcc43a881c34ebea20a48b86912eba674.png"
import lifePay from "../images/partners/arenza_partner_livepay-a13542d40b0b886ffddcdb48a401506da567efd00d4aa17803ddfbe5303cc94a.png"
import myhoreca from "../images/partners/arenza_partner_myihoreca-2da5df2bb3b78ebd1f83bbd92957266ca3b3d388423d7e08e43a2a9e4e10e681.jpeg"
import profiFit from "../images/partners/arenza_partner_profi-b7df3f6bb78a4d06d003be20ce956ca470b176785b63eb580c72989a990d08b9.png"
import psbank from "../images/partners/arenza_partner_promsvyazbank-bebf49e2bf459166a1b7d99f3a56220005da8b62f0dba540843445512928da7c.jpeg"
import pult from "../images/partners/arenza_partner_pult-9b863da51293033c51f05bce74554921e454593f3a4e0bbfd33be37c36dd013e.png"
import quickresto from "../images/partners/arenza_partner_quicresto-90bb8b265a5d31ae4d26a38be60a3b7a40141ffb70f2e957dcb00871da44362e.png"
import refro from "../images/partners/arenza_partner_refro-cab64bd5b3027a9aed2659fe7ce3273f34db9c0c9bc35aabe3a408261c7f1d17.png"
import trade43 from "../images/partners/arenza_partner_stankoles-12fbd7ff795e1596767906cb289f8fcc50348ca3f6d81725023ddba0e1377e8f.jpeg"
import stimawellEms from "../images/partners/arenza_partner_stimawell-125b61034740021e64fc1a90524dada892d8607bce0b689a50d6d6eaf4259d64.png"
import svaiMaster from "../images/partners/arenza_partner_svaymaster-68ace633abfffc2749e6a4307f77caffc69b1b3a35b624b403f87dd1096273f3.png"
import tinkoff from "../images/partners/arenza_partner_tinkoff-bc629acfccb2ee8310ed818f8fa94779e3202d00e01c98a5b431feb1fe10fd1d.png"
import partnerTochka from "../images/partners/arenza_partner_tochka-3fae6967b5efca020271429699b48cd5d2ee851ac62da3b74777505734eb8e51.png"
import trustHolod from "../images/partners/arenza_partner_trastholod-331404393173e9542f94fee2e3d2d1cbe43a2764337964d2c5c288eaa079ea86.png"
import triol1 from "../images/partners/arenza_partner_triol-19d1cf8f84a11b08761d7cec3b962ce032c50fe252cad8b8431d6bf57e46c31d.png"
import vavilonVending from "../images/partners/arenza_partner_vavilon-07524df23016aa950d8c75c5488b9dccd3dd30a095ac756b3daf13eff8cf26cc.png"
import vendshop from "../images/partners/arenza_partner_vendshop-462efa8cac2e366d597e4472506b7ed4515431383ac07eba77466049f32ed48a.jpeg"


export const partners = [
  {
    img: partnerTochka,
    alt: 'partner.tochka',
    href: 'https://partner.tochka.com/?referer1=arenza',
  },
  {
    img: biztechnology,
    alt: 'biztechnology',
    href: 'http://www.biztechnology.ru/',
  },
  {
    img: garo,
    alt: 'garo',
    href: 'http://www.garo.cc/',
  },
  {
    img: vendshop,
    alt: 'vendshop',
    href: 'http://vend-shop.com/',
  },
  {
    img: pult,
    alt: 'pult',
    href: 'https://www.pult.ru/',
  },
  {
    img: tinkoff,
    alt: 'tinkoff',
    href: 'https://www.tinkoff.ru/business/?utm_source=partner_rko_sme&utm_medium=ptr.act&utm_campaign=sme.partners&partnerId=5-BO8GTZFS#form-application',
  },
  {
    img: alfabank,
    alt: 'alfabank',
    href: 'https://alfabank.ru/',
  },
  {
    img: psbank,
    alt: 'psbank',
    href: 'https://www.psbank.ru/',
  },
  {
    img: cassby,
    alt: 'cassby',
    href: 'https://cassby.com',
  },
  {
    img: iport,
    alt: 'iport',
    href: 'http://www.iport.ru/',
  },
  {
    img: kns,
    alt: 'kns',
    href: 'https://www.kns.ru/',
  },
  {
    img: lifePay,
    alt: 'life-pay',
    href: 'https://www.life-pay.ru/',
  },
  {
    img: myhoreca,
    alt: 'myhoreca',
    href: 'http://myhoreca.ru',
  },
  {
    img: profiFit,
    alt: 'profi.fit',
    href: 'https://profi.fit',
  },
  {
    img: quickresto,
    alt: 'quickresto',
    href: 'https://quickresto.ru/',
  },
  {
    img: triol1,
    alt: 'triol1',
    href: 'http://www.triol1.ru/',
  },
  {
    img: refro,
    alt: 'refro',
    href: 'https://www.refro.ru/',
  },
  {
    img: trade43,
    alt: 'trade43',
    href: 'https://trade43.ru/',
  },
  {
    img: stimawellEms,
    alt: 'stimawell-ems',
    href: 'https://stimawell-ems.ru/',
  },
  {
    img: svaiMaster,
    alt: 'svai-master5000',
    href: 'https://svai-master5000.ru',
  },
  {
    img: trustHolod,
    alt: 'trust-holod',
    href: 'http://trust-holod.ru',
  },
  {
    img: vavilonVending,
    alt: 'vavilon-vending',
    href: 'http://www.vavilon-vending.ru/',
  },
]