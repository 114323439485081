import React from 'react';

import { Box, Typography } from '@material-ui/core';
import { AboutSectionContainer } from '@product-site-frontend/shared';

export default function SectionBecomePartner() {
  const styles = {
    titleH2: {
      mb: { xs: 4, md: 5, lg: 6 },
      textAlign: 'center',
      fontSize: '36px !important',
      fontWeight: 700,
    },
    blockWrapper: {
      display: { xs: 'block', lg: 'flex' },
      justifyContent: 'space-between',
      mb: 8,
    },
    block: {
      width: { xs: '100%', lg: 'calc(100% / 3)' },
      margin: { lg: "0 30px" },
      marginBottom: { xs: '40px', lg: '20px' },
      textAlign: 'center',
      position: 'relative',

      '&:first-child': {
        marginLeft: '0'
      },
      '&:last-child': {
        marginRight: '0'
      },
      '&::after': {
        content: '" "',
        width: { xs: '50%', lg: '1px' },
        height: { xs: '1px', lg: '25%' },
        background: '#d9dcde',
        display: 'block',
        position: 'absolute',
        top: { xs: '120%', lg: '25%' },
        right: { xs: '25%', lg: '-10%' },
      },
      '&:last-child::after': {
        display: 'none',
      }
    },
    titleBlock: {
      marginBottom: '15px',
      fontSize: { xs: '26px', lg: '18px' },
      fontWeight: 500,
    },
    descriptionBlock: {
      fontSize: { xs: '18px', lg: '16px' },
    }
  }

  return (
    <div style={{
      background: "linear-gradient(12deg, #e97f37 0%, #e97f37 50%, #f6863a 50%, #f6863a 100%)",
      color: "#FFF",
      paddingTop: '1px',
    }}>
      <AboutSectionContainer sxContainer={{ mb: { xs: 5, lg: 8 } }}>
      <Typography sx={{ ...styles.titleH2 }} variant="h2">
        Стать партнером Arenza — это просто
      </Typography>

      <Box sx={{ ...styles.blockWrapper }}>
        <Box sx={{ ...styles.block }}>
          <Typography component="div" sx={{ ...styles.titleBlock }}>
            Заявка
          </Typography>
          <Typography component="div" sx={{ ...styles.descriptionBlock }}>
            Вы оставляете контактную информацию — мы связываемся с вами в течение 24 часов
          </Typography>
        </Box>
        <Box sx={{ ...styles.block }}>
          <Typography component="div" sx={{ ...styles.titleBlock }}>
            Выбор формата партнерства
          </Typography>
          <Typography component="div" sx={{ ...styles.descriptionBlock }}>
            Мы предлагаем гибкие условия партнерства и можем разработать и подключить кнопку “Купить в лизинг” для партнерских сайтов бесплатно
          </Typography>
        </Box>
        <Box sx={{ ...styles.block }}>
          <Typography component="div" sx={{ ...styles.titleBlock }}>
            Соглашение
          </Typography>
          <Typography component="div" sx={{ ...styles.descriptionBlock }}>
            Мы подписываем партнерское соглашение с обеих сторон и начинаем работу
          </Typography>
        </Box>
      </Box>
    </AboutSectionContainer>
    </div>
  );
}