import React from 'react';

import { Box, Typography } from '@material-ui/core';
import { AboutSectionContainer } from '@product-site-frontend/shared';

import img1 from "./images/icon_1-0276272467a67a14528b9b906ec8a17f1daacedf3c85cab052325a3583b7f647.png";
import img2 from "./images/icon_2-f4c59178316a1f4b871ae25349a1525b05d067b8637ab8b09eac1eb843cb6ab8.png";
import img3 from "./images/icon_3-6811696bbe7f302e7ec5a04d554c6a858cbe8574fd0ef5ac0f838c4802f2adc7.png";

export default function SectionHowWork() {
  const styles = {
    titleH2: {
      fontSize: '36px',
      textAlign: 'center',
      fontWeight: 'bold',
      marginBottom: '74px'
    },
    blockWrapper: {
      display: { xs: 'block', lg: 'flex' },
      justifyContent: 'space-between',
      mb: 8,
      gap: '30px',
    },
    block: {
      width: { xs: '100%', lg: 'calc(100% / 3)' },
      display: { xs: 'flex', lg: 'block' },

      '&:last-child .MuiTypography-root::before': {
        display: 'none',
      },
    },
    imgBlock: {
      height: '64px',
      width: { xs: '62px', lg: 'auto' },
      display: 'block',
      margin: '0 auto 36px',
      textAlign: 'center',

      '& img': {
        display: 'inline-block'
      }
    },
    titleBlockNumber: {
      border: '2px #5c6b7e solid',
      borderRadius: '25px',
      height: '39px',
      width: '39px',
      display: 'flex',
      alignItems: 'center',
      alignContent: 'center',
      justifyContent: 'center',
      fontSize: '14px',
      fontWeight: 'bold',
      margin: '0 auto 20px',
      backgroundColor: '#ffffff',
      position: 'relative',

      '&::before': {
        content: '""',
        height: { xs: '100px', lg: '1px' },
        width: { xs: '1px', lg: '377px' },
        position: 'absolute',
        border: '1px #5c6b7e dashed',
        left: { xs: 'unset', lg: '100%' },
        top: { xs: '100%', lg: '50%' },
      },
    },
    titleBlock: {
      fontSize: { xs: '26px', lg: '15px' },
      fontWeight: 500,
      textAlign: { xs: 'left', lg: 'center' },
      marginBottom: '10px',
      width: { xs: '230px', lg: 'auto' },
    },
    descriptionBlock: {
      fontSize: { xs: '18px', lg: '14px' },
      fontWeight: 300,
      lineHeight: '16px',
      textAlign: { xs: 'left', lg: 'center' },
      width: { xs: '230px', lg: 'auto' },
      marginBottom: { xs: '18px', lg: '0' },
    }
  }

  return (
    <AboutSectionContainer id="howtowork" sxContainer={{ mb: { xs: 5, lg: 8 } }}>
      <Typography sx={{ ...styles.titleH2 }} variant="h2">
        Как это работает?
      </Typography>

      <Box sx={{ ...styles.blockWrapper }}>
        <Box sx={{ ...styles.block }}>
          <Box sx={{ ...styles.imgBlock }}>
            <img alt={'Выбор товара'} src={img1} />
          </Box>
          <Typography component="div" sx={{ ...styles.titleBlockNumber }}>
            1
          </Typography>

          <Box>
            <Typography component="div" sx={{ ...styles.titleBlock }}>
              Выбор товара
            </Typography>
            <Typography component="div" sx={{ ...styles.descriptionBlock }}>
              Ваш клиент выбирает необходимый товар
            </Typography>
          </Box>
        </Box>
        <Box sx={{ ...styles.block }}>
          <Box sx={{ ...styles.imgBlock }}>
            <img alt={'Оплата товара'} src={img2} />
          </Box>
          <Typography sx={{ ...styles.titleBlockNumber }}>
            2
          </Typography>

          <Box>
            <Typography component="div" sx={{ ...styles.titleBlock }}>
              Оплата товара
            </Typography>
            <Typography component="div" sx={{ ...styles.descriptionBlock }}>
              Мы оплачиваем вам всю стоимость товара. <br /> Вы осуществляете отгрузку вашему клиенту
            </Typography>
          </Box>
        </Box>
        <Box sx={{ ...styles.block }}>
          <Box sx={{ ...styles.imgBlock }}>
            <img alt={'Наши расчеты с клиентом'} src={img3} />
          </Box>
          <Typography sx={{ ...styles.titleBlockNumber }}>
            3
          </Typography>

          <Box>
            <Typography component="div" sx={{ ...styles.titleBlock }}>
              Наши расчеты с клиентом
            </Typography>
            <Typography component="div" sx={{ ...styles.descriptionBlock }}>
              Далее клиент рассчитывается с нами доступными ежемесячными платежами
            </Typography>
          </Box>
        </Box>
      </Box>
    </AboutSectionContainer>
  );
}