export const reviews = [
  {
    name: 'Дмитрий Попов',
    text: 'Первый ресторан я открыл летом 2013 года. В нашем городе немного японских ресторанов, нишу я выбрал правильную — спрос большой. В прошлом году мы приняли решение расширяться. Оборудование для нового ресторана понадобилось срочно — мы выбрали все нужное на <a target="_blank" href="http://www.klenmarket.ru/" tabindex="0">www.klenmarket.ru</a>, но не знали, как профинансировать покупку. Времени на оформление кредита не было, уверенности, что мы его получим, тоже. Тогда мы решили опробовать Arenza — оказалось, что это очень удобный инструмент для финансирования бизнеса. Мы получили оборудование для ресторана в течение недели. Мы точно будем еще пользоваться Arenza, возможно, развивать франшизу — в этом случае обязательно предложим такой инструмент будущим франчайзи.',
    company: 'Суши Кук (г. Долгопрудный)',
    site: '',
    star: 5,
  },
  {
    name: 'Алексей Кайгородов',
    text: 'Наша компания c 2014 года занимается сдачей робототехники в аренду, организацией мероприятий и немного разработкой. После открытия нам нужно было срочно приобрести дорогого большого робота — средств на такую покупку не хватало, а на волокиту с кредитом времени не было. Я много слышал про Arenza, поэтому решил совершить покупку в лизинг. Оказалось, что это очень быстро и удобно.',
    company: 'Rent Robot',
    site: 'http://rent-robot.ru/',
    star: 5,
  },
  {
    name: 'Николай Грачев',
    text: 'Мы открыли первое кафе Хинкали Point полтора года назад. Сейчас у нас уже два заведения, скоро планируем открыть еще одно, но уже с другой кухней — вьетнамской. Перед открытием второй хинкальной мы поняли, что нам не обойтись без финансирования оборудования. Выбрали все необходимое на <a target="_blank" href="http://www.klenmarket.ru/" tabindex="0">www.klenmarket.ru</a>, а с покупкой помогла Arenza. Ставка адекватная, все очень удобно, оборудование мы получили быстро, без проблем и волокиты. Будем еще работать с Arenza, ведь скоро открывать вьетнамское кафе!',
    company: 'Хинкали Point',
    site: '',
    star: 5,
  },
  {
    name: 'Алла Белова',
    text: 'Я — начинающий предприниматель. Я запустила производство двухслойных фермерских ягодных десертов, которые можно купить в сети «ВкусВилл». Несмотря на то, что я никогда не пускалась в такие авантюры и это первый опыт ведения бизнеса, получить финансирование с Arenza было легко. Меньше недели — и все оборудование уже было у меня. Надеюсь, что теперь все получится! Пожелайте мне удачи!',
    company: 'Дессертино',
    site: '',
    star: 5,
  },
  {
    name: 'Дарья Шарапаева',
    text: 'У меня свое бистро-столовая в Лефортово — локальный аналог сети «Обед-Буфет». После оплаты аренды и части оборудования я поняла, что мне не хватает средств на холодильники, и именно тогда я решила воспользоваться Arenza. Оказалось, что лизинг — это самый приемлемый вариант для молодого бизнеса. Удобные условия, нормальный процент и ставка, оборудование можно получить очень быстро. Если решим расширяться, то обязательно вместе с Arenza.',
    company: 'Помидорка',
    site: '',
    star: 5,
  },
  {
    name: 'Ксения Мандрик',
    text: 'Осенью 2016 года мы поняли, что пора расширяться. Взяли кредит на аренду помещения и осознали, что нужно профинансировать и покупку оборудования. Решили попробовать лизинг от Arenza, чтобы не ввязываться в долгую банковскую историю. Сервис очень понравился — отличная идея, удобный и понятный продукт. Благодаря Arenza мы быстро получили оборудование и в феврале запустили вторую кофейню',
    company: 'Drinkit',
    site: '',
    star: 5,
  },
  {
    name: 'Юрий',
    text: 'Главная проблема при открытии фитнес клуба — очень дорогое оборудование. Стоимость всего необходимого часто достигает 5-6 миллионов рублей. Брать оборудование &nbsp;в лизинг очень удобно, а предложение Arenza показалось мне самым привлекательным и выгодным. Мы получили одобрение по заявке и само оборудование в течение нескольких дней, и сейчас уже успешно запустили бизнес. Уверен, что еще не раз буду работать с Arenza.',
    company: 'Фитнес клуб',
    site: '',
    star: 5,
  },
  {
    name: 'Павел',
    text: 'Мы уже много лет продаем профессиональные кофемашины Melvita для ресторанов и кофеен. Об Arenza &nbsp;узнали от знакомых, которые уже работали с сервисом и остались им очень довольны. Такого продукта на рынке не хватало, потому что качественных приложений для развития бизнеса не так много. Предпринимателям необходим удобный, быстрый и простой формат финансирования для покупок — лизинг как раз про это. Планируем продолжать работать с Arenza и думаем, что сервис приведет к нам еще много качественных клиентов.',
    company: 'МП Трейд',
    site: '',
    star: 5,
  },
]