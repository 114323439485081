import React, { useEffect, useState } from "react";

import Breadcrumbs from '../../../shared/components/Breadcrumbs';
import PageBox from '../components/PageBox';
import {
  SectionIncreaseSales,
  SectionHowWork,
  SectionWhyArenza,
  SectionBecomePartner,
  SectionSlider,
  SectionCarousel,
  SectionForm
} from "../components/PartnersSections";
import SEO from '../components/SEO';

export default function PartnersPage(props) {
  const [topHowWork, setTopHowWork] = useState(0)
  const [topForm, setTopForm] = useState(0)

  useEffect(() => {
    const elemHowToWork = document.getElementById("howtowork");
    let positionHowToWork = elemHowToWork.getBoundingClientRect();
    setTopHowWork(positionHowToWork.top - 130);

    const elemSectionForm = document.getElementById("sectionform");
    let positionSectionForm = elemSectionForm.getBoundingClientRect();
    setTopForm(positionSectionForm.top - 130);
  }, [])

  return (
    <PageBox>
      <SEO pageData={{
        title: 'Сотрудничество с лизинговой компанией Arenza: информация для партнеров',
        description: 'Подробная информация для будущих партнеров лизинговой компании Arenza'
      }} />
      <Breadcrumbs
        breadcrumbs={[
          {
            href: '/',
            text: 'Домашняя страница'
          },
          {
            text: 'Партнёрам'
          }
        ]}
      />
      <SectionIncreaseSales topForm={topForm} topHowWork={topHowWork} />
      <SectionWhyArenza />
      <SectionHowWork />
      <SectionBecomePartner />
      <SectionSlider />
      <SectionCarousel />
      <SectionForm />
    </PageBox>
  );
}
